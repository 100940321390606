import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';

function MSPSettingsEvsRevertTemplate() {
  const [isReverting, setIsReverting] = useState(false);
  const { t } = useTranslation();
  const { me } = useCurrentUser();
  const { showErrorMessage, showSuccessMessage } = useToast();

  const { reportTemplate } = me.msp.links;

  const onClick = () => {
    setIsReverting(true);

    fetch(reportTemplate, {
      method: 'DELETE',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        showSuccessMessage(t('Template reverted successfully'));
        return response.json();
      })
      .then(() => {
        setIsReverting(false);
      })
      .catch((error) => {
        showErrorMessage(error.message || t('Something went wrong'));
        setIsReverting(false);
      });
  };
  return (
    <Button
      className="MSPSettingsEvsRevertTemplate flex gap-2 items-center"
      Icon={<FontAwesomeIcon icon={light('backward')} />}
      loading={isReverting}
      onClick={onClick}
      size="xs"
      variant="dangerous"
    >
      {t('Revert to default template')}
    </Button>
  );
}

export default MSPSettingsEvsRevertTemplate;
