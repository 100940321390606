export const VulnerabilityStatus = Object.freeze({
  CURRENT: 'CURRENT',
  NEW: 'NEW',
  REMEDIATED: 'REMEDIATED',
});

export const VulnerabilitySeverity = Object.freeze({
  CRITICAL: 4,
  HIGH: 3,
  INFO: 0,
  LOW: 1,
  MEDIUM: 2,
});

export const VulnerabilityAssetStatus = Object.freeze({
  ...VulnerabilityStatus,
  PREVIOUSLY_IDENTIFIED: 'PREVIOUSLY_IDENTIFIED',
});
