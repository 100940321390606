import React, { createContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { COMPANY_QUERY } from 'src/api/companies';
import useEvsTacticData, { evsTacticInitialData } from 'src/hooks/useEvsTacticData';
import useUserParams from 'src/hooks/useUserParams';

export const CompanyContext = createContext({
  company: null,
  error: null,
  hasLoaded: false,
  /**
   * Loading state for the current company and all tactics data
   */
  loading: true,
  tactics: {
    evs: evsTacticInitialData,
  },
});

export function CompanyProvider(props) {
  const { children } = props;
  const { companyId, mspId } = useUserParams();

  const companyQuery = useQuery(COMPANY_QUERY, { variables: { companyId } });

  const { company } = companyQuery.data || { company: null };

  const evs = useEvsTacticData({ companyId, mspId });

  const error = companyQuery.error || evs.error;
  const loading = companyQuery.loading || evs.loading;
  const hasLoaded = !evs.loading && !!evs.companyEvsSubscription;

  const value = useMemo(() => ({
    company,
    error,
    hasLoaded,
    loading,
    tactics: {
      evs,
    },
  }), [company, error, hasLoaded, loading, evs]);

  return (
    <CompanyContext.Provider value={value}>
      {children}
    </CompanyContext.Provider>
  );
}

CompanyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useCurrentCompany = () => React.useContext(CompanyContext);
