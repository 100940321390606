import React from 'react';

import PropTypes from 'prop-types';

import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';

function MSPDashboardNewsTableViewRow(props) {
  const { data: news } = props;

  return (
    <tr id={news.id}>
      <td>
        <FieldDatetime value={new Date(news.date)} />
      </td>
      <td>
        <pre
          className="code text-sm bg-od-black-900 p-2 rounded-md overflow-x-auto"
        >
          {news.text}
        </pre>
      </td>
    </tr>
  );
}

MSPDashboardNewsTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
};

export default MSPDashboardNewsTableViewRow;
