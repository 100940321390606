import { from, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import cookie from 'cookie';
import i18n from 'i18next';

import { isDevelopment } from 'src/environment';
import useToast from 'src/hooks/useToast';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({
  graphQLErrors,
  networkError,
  operation,
  response,
}) => {
  const { showErrorMessage } = useToast();

  const isAuthPage = window.location.pathname.includes('/auth/');
  const isHomePage = window.location.pathname === '/';

  if (networkError) {
    if (networkError.statusCode === 403) {
      if (!isAuthPage) {
        window.location.replace('/users/login/');

        if (!isHomePage) {
          showErrorMessage(i18n.t('User is not authorized'));
        }
      }
    } else {
      showErrorMessage(networkError.message);
    }
    if (isDevelopment()) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }
  }

  // TODO: Reconsider this approach
  if (!operation.variables.ignoreErrors) {
    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      graphQLErrors.forEach(({ locations, message, path }) => console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ));

      if (!networkError) {
        showErrorMessage(i18n.t(graphQLErrors[0].message));
        response.errors = null;
      } else if (isDevelopment()) {
        showErrorMessage(i18n.t('Development: Something went wrong. Check your console'));
      }
    }
  }
});

const httpLink = createHttpLink({
  uri: '/graphql/',
});

const csrfLink = setContext((_, { headers, ...rest }) => {
  const cookies = cookie.parse(document.cookie);

  const csrfToken = cookies.csrftoken;

  return {
    headers: {
      ...headers,
      'X-CSRFToken': csrfToken,
    },
    ...rest,
  };
});

const additiveLink = from([
  errorLink,
  csrfLink,
  httpLink,
]);

// Don't cache separate results based on
// any of this field's arguments.
// keyArgs: []

// Concatenate and flatten the incoming list items with
// the existing list items.
function merge(existing, incoming) {
  if (existing) {
    if (incoming) {
      return {
        edges: [
          ...existing.edges, // Existing items were flattened previously
          ...incoming.edges,
        ],
        pageInfo: incoming.pageInfo,
      };
    }
    return {
      edges: [
        ...existing.edges, // Existing items were flattened previously
      ],
      pageInfo: existing.pageInfo,
    };
  }
  if (incoming) {
    return {
      edges: [
        ...incoming.edges, // Existing items were flattened previously
      ],
      pageInfo: incoming.pageInfo,
    };
  }
  throw new Error('Something was wrong here. Missing existing or incoming input');
}

// Make sure we've got CORS requests to /graphql locked down on the backend
const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allBreachedCredentials: {
            keyArgs: [],
            merge,
          },
          companies: relayStylePagination(),
          users: {
            keyArgs: [],
            merge,
          },
        },
      },
      ScanStatisticsNode: {
        fields: {
          vulnCountPerLevel: {
            merge: true,
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  link: additiveLink,
});

export default apolloClient;
