import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BCI_DOMAINS, BCI_SET_ENABLED_DOMAIN_MUTATION } from 'src/api/bci';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function DomainToggleSubscriptionButton(props) {
  const { domain } = props;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();

  const [setEnabledDomain, { loading: saving }] = useMutation(BCI_SET_ENABLED_DOMAIN_MUTATION, {
    onCompleted(response) {
      if (!response.bciSetEnabledDomain) {
        return;
      }
      if (response.bciSetEnabledDomain.domain.enabled) {
        showSuccessMessage(t('Subscription for "{{domain}}" resumed successfully', { domain }));
      } else {
        showSuccessMessage(t('Domain "{{domain}}" unsubscribed successfully', { domain }));
      }
      setIsOpenConfirmationModal(false);
    },
    refetchQueries: [BCI_DOMAINS],
  });

  const onToggle = async () => {
    await setEnabledDomain({
      variables: {
        companyId,
        domain,
        enabled: !domain.enabled,
      },
    });
  };

  const title = domain.enabled
    ? t('Unsubscribe domain "{{domain}}"', { domain })
    : t('Resume subscription for "{{domain}}"', { domain });
  const subtitle = domain.enabled
    ? t('Are you sure you want to unsubscribe this domain?'
      + ' Doing so will result in the deletion of this domain’s data after your billing period has ended.')
    : t('This action will resume this domain’s subscription and keep all the domain’s data and costs');

  return (
    <>
      {domain.enabled && (
        <Button
          Icon={<FontAwesomeIcon icon={light('ban')} />}
          onClick={() => setIsOpenConfirmationModal(true)}
          size="sm"
          variant="dangerous"
        >
          {t('Unsubscribe')}
        </Button>
      )}
      {!domain.enabled && (
        <Button
          Icon={<FontAwesomeIcon icon={light('play')} />}
          onClick={() => setIsOpenConfirmationModal(true)}
          size="sm"
        >
          {t('Resume')}
        </Button>
      )}
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            Icon={<FontAwesomeIcon icon={light('ban')} />}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            Icon={<FontAwesomeIcon icon={light('thumbs-up')} />}
            loading={saving}
            onClick={onToggle}
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={subtitle}
        title={title}
      />
    </>
  );
}

DomainToggleSubscriptionButton.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default DomainToggleSubscriptionButton;
