import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

// import MSPSettingsBCIReportsSection from './EVSSettingsBCIReportsSection';
import MSPSettingsEVSReportsSection from './MSPSettingsEVSReportsSection';
import MSPSettingsLogoUploadSection from './MSPSettingsLogoUploadSection';

export default function MSPSettings() {
  const { t } = useTranslation();
  const { me } = useCurrentUser();

  const title = t('{{msp}} | Settings', { msp: me.msp.name });

  return (
    <Page title={title}>
      <h1>{title}</h1>

      <MSPSettingsLogoUploadSection />

      <section className="mt-10">
        <h2>{t('Tactic report templates')}</h2>

        <p className="text-sm text-od-white-500">
          You can download a sample document, fill it in, and re-upload it here
        </p>

        <MSPSettingsEVSReportsSection />

        {/* <MSPSettingsBCIReportsSection /> */}
      </section>
    </Page>
  );
}
