import React from 'react';

import PropTypes from 'prop-types';

function Card(props) {
  const { children } = props;
  return (
    <div className="Card bg-od-black-500 rounded-od-20 px-5 py-5">
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
