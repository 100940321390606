import React from 'react';

import { useQuery } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MSP_BILLING_COMPANY_DETAILS } from 'src/api/billing';
import { isSubscriptionActive } from 'src/constants/subscription';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import useUserParams from 'src/hooks/useUserParams';
import EvsNotificationSettings from 'src/views/TacticEvs/EvsNotificationSettings';

function TacticVulnerabilitiesDetails({ evsTargetGroups }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useCurrentUser();
  const { companyId } = useUserParams();

  const { tactics: { evs: { companyEvsSubscription, evsSubscriptionStatus } } } = useCurrentCompany();

  const skip = !permissions.canViewBillingDetails() || !isSubscriptionActive(evsSubscriptionStatus);
  const { data } = useQuery(MSP_BILLING_COMPANY_DETAILS, {
    skip,
    variables: { id: companyId },
  });

  const { mspBillingCompanyDetails } = data || { mspBillingCompanyDetails: null };

  return (
    <section className={classNames('mt-8 grid grid-cols-2 gap-4', {
      'xl:grid-cols-4': !permissions.canViewBillingDetails(),
      'xl:grid-cols-6': permissions.canViewBillingDetails(),
    })}
    >

      <InfoCard description={t('Groups')}>
        {evsTargetGroups.length}
      </InfoCard>

      <InfoCard description={t('Targets')}>
        {companyEvsSubscription.targetCount}
      </InfoCard>

      {permissions.canViewBillingDetails() && mspBillingCompanyDetails && (
        <InfoCard
          className="lg:col-span-2"
          description={t("Next month's cost")}
        >
          <div className="flex gap-2 justify-between">
            <h4>
              <FieldCost value={mspBillingCompanyDetails.nextPayment.cost} />
            </h4>

            {permissions.canSetupPaymentMethod() && (
              <Button
                Icon={<FontAwesomeIcon icon={light('file-invoice-dollar')} />}
                onClick={() => navigate(`/billing/companies/${companyId}`)}
                size="sm"
                variant="secondary"
              >
                {t('Billing')}
              </Button>
            )}
          </div>
        </InfoCard>
      )}

      {permissions.canManageEvsNotificationSettings() && (
        <EvsNotificationSettings />
      )}
    </section>
  );
}

TacticVulnerabilitiesDetails.propTypes = {
  evsTargetGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TacticVulnerabilitiesDetails;
