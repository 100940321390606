import React from 'react';

import { useTranslation } from 'react-i18next';

import useBreachCredentialsLoader from 'src/api/loaders/useBreachCredentialsLoader';
import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import TableView from 'src/components/common/Table/TableView';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconBreachCredentials from 'src/design/custom-icons/IconBreachCredentials/IconBreachCredentials';
import Button from 'src/design/ui-kit/Button/Button';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';

import BreachCredentialsTableViewRow from './BreachCredentialsTableViewRow';
import useBreachCredentialsColumns from './useBreachCredentialsColumns';

const first = 20;

// TODO: Add New badge
function BreachCredentialsDomain() {
  const { t } = useTranslation();
  const columns = useBreachCredentialsColumns();
  const { companyId, domain } = useUserParams();
  const { userConfig } = useCurrentUser();

  const {
    data: { items: allBreachedCredentials, pageInfo },
    error,
    fetchMore,
    hasLoaded,
    isLoadingMore,
    loading,
  } = useBreachCredentialsLoader({
    variables: {
      companyId,
      domain,
      first,
      orderByFields: ['date'],
    },
  });

  // TODO: Handle error if directly hit the URL

  if (error || !hasLoaded) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  if (allBreachedCredentials.length === 0 && !loading) {
    return (
      <Page title={t('{{domain}} Breach Credentials', { domain })}>
        <EmptyState
          subtitle={t('To set up your first tactic, click the "Open tactics" button below!')}
          title={t("Here's where you'll see an overview of your breach credentials")}
        >
          <Button
            link={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}
          >
            {t('Open tactics')}
          </Button>
        </EmptyState>
      </Page>
    );
  }

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        after: pageInfo.endCursor,
        domain,
        first,
      },
    });
  };

  return (
    <Page title={t('{{domain}} - Breach Credentials identified', { domain })}>
      <header className="grid gap-4">
        <div className="flex items-center gap-4">
          <IconBreachCredentials />
          <h1>{t('{{domain}} - Breach Credentials identified', { domain })}</h1>
        </div>

        <GoBackLink to={userConfig.buildUrl('/breach-credentials', `/${companyId}`)}>
          {t('Breach credentials dashboard')}
        </GoBackLink>
      </header>

      <div className="mt-10">
        <TableView
          columns={columns}
          data={allBreachedCredentials}
          isLoadingMore={isLoadingMore}
          onLoadMore={onLoadMore}
          pageInfo={pageInfo}
          rowTemplate="minmax(64px, max-content)"
          TableRow={BreachCredentialsTableViewRow}
        />
      </div>
    </Page>
  );
}

export default BreachCredentialsDomain;
