import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { VulnerabilityAssetStatus, VulnerabilitySeverity, VulnerabilityStatus } from 'src/constants/evs';
import colors from 'src/design/theme/colors';

const countPerLevel = {
  critical: 0,
  high: 0,
  info: 0,
  low: 0,
  medium: 0,
  total: 0,
};

export function getVulnerabilitiesTotalByStatus({ showInfo, vulnerabilities }) {
  const total = vulnerabilities.length;
  if (showInfo) {
    return total;
  }

  return total - vulnerabilities.filter(({ severity }) => severity === VulnerabilitySeverity.INFO).length;
}

export function useVulnerabilityStatuses({ scan }) {
  const { t } = useTranslation();

  const currentCounts = scan?.statistics?.currentCount || countPerLevel;
  const newCounts = scan?.deltaStatistics?.newCount || countPerLevel;
  const remediatedCounts = scan?.deltaStatistics?.remediatedCount || countPerLevel;

  return [
    {
      counts: currentCounts,
      id: VulnerabilityStatus.CURRENT,
      text: t('Current vulnerabilities {{count}}', {
        count: currentCounts.total,
      }),
    },
    {
      counts: newCounts,
      id: VulnerabilityStatus.NEW,
      text: t('New vulnerabilities {{count}}', {
        count: newCounts.total,
      }),
    },
    {
      counts: remediatedCounts,
      id: VulnerabilityStatus.REMEDIATED,
      text: t('Remediated vulnerabilities {{count}}', {
        count: remediatedCounts.total,
      }),
    },
  ];
}

export function useScanVulnerabilitySeverityDefinitions({ showInfo }) {
  const { t } = useTranslation();
  const types = [
    {
      color: colors['od-complementary-critical'],
      key: 'critical',
      name: t('Critical'),
      severity: VulnerabilitySeverity.CRITICAL,
    },
    {
      color: colors['od-complementary-high'],
      key: 'high',
      name: t('High'),
      severity: VulnerabilitySeverity.HIGH,
    },
    {
      color: colors['od-complementary-medium'],
      key: 'medium',
      name: t('Medium'),
      severity: VulnerabilitySeverity.MEDIUM,

    },
    {
      color: colors['od-complementary-low'],
      key: 'low',
      name: t('Low'),
      severity: VulnerabilitySeverity.LOW,
    },
  ];
  if (showInfo) {
    types.push({
      color: colors['od-complementary-info'],
      key: 'info',
      name: t('Info'),
      severity: VulnerabilitySeverity.INFO,
    });
  }
  return types;
}

export const useVulnerabilityAssetStatusTextMap = () => Object.freeze({
  [VulnerabilityAssetStatus.CURRENT]: i18n.t('Current'),
  [VulnerabilityAssetStatus.NEW]: i18n.t('New'),
  [VulnerabilityAssetStatus.PREVIOUSLY_IDENTIFIED]: i18n.t('Previously identified'),
  [VulnerabilityAssetStatus.REMEDIATED]: i18n.t('Remediated'),
});
