import { gql } from '@apollo/client';

import FragmentBciStatistics from 'src/api/fragments/FragmentBciStatistics';
import FragmentBciSubscription from 'src/api/fragments/FragmentBciSubscription';

import FragmentEvsSubscription from './fragments/FragmentEvsSubscription';

export const COMPANIES_QUERY = gql`
  ${FragmentEvsSubscription}
  ${FragmentBciSubscription}
  ${FragmentBciStatistics}
  
  query Companies($first: Int!, $after: String, $enabled: Boolean, $orderBy: String, $name_Icontains: String) {
    companies(
      first: $first
      after: $after
      enabled: $enabled
      orderBy: $orderBy
      name_Icontains: $name_Icontains
    ) @connection(key: "id") {
      edges {
        node {
          id
          name
          
          enabled

          manager {
            id
            name
          }
  
          tacticsCount
          usersCount
          
          bcStatistics {
            ...BCStatistics
          }
          
          bciSubscription {
            ...BCISubscriptionNode
          }
          
          evsSubscription {
            ...EVSSubscriptionNode
          }
          
          evsAggregateStatistics {
            currentCount {
              total
            }
            remediatedCount {
              total
            }
            newCount {
              total
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const COMPANY_QUERY = gql`
  query($companyId: ID!) {
    company(id: $companyId) {
      id
      name

      manager {
        id
        name
      }

      tacticsCount
      usersCount
      
      evsAggregateStatistics {
        currentCount {
          total
        }
        remediatedCount {
          total
        }
        newCount {
          total
        }
      }
    }
  }
`;

export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.companies.edges.map((edge) => edge.node),
    pageInfo: data.companies.pageInfo,
  };
}

/**
 * manager
 * id of the MSP that manages the company, if any.
 * Null means the company is managed globally, by Snowfensive.
 */
export const CREATE_COMPANY_MUTATION = gql`
  mutation createCompany($name: String!, $manager: ID) {
    createCompany(input: {
      name: $name
      manager: $manager
    }) {
      company {
        id
        name
        manager {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation($id: ID!, $name: String!, $mspId: ID, $enabled: Boolean!) {
    updateCompany(input: {
      id: $id
      name: $name
      manager: $mspId
      enabled: $enabled
    }) {
      company {
        id
        name
        manager {
          id
          name
        }
        enabled
      }
    }
  }
`;
