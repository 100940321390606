import React, { useRef, useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';

function MSPSettingsEvsUploadTemplate() {
  const { t } = useTranslation();

  const { me } = useCurrentUser();
  const { showErrorMessage } = useToast();

  const { reportTemplate } = me.msp.links;
  const inputRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);

  const onClick = () => {
    inputRef.current.click();
  };

  const handleFileUpload = (file) => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setIsUploading(true);

    fetch(reportTemplate, {
      body: formData,
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        setIsUploading(false);
      })
      .catch((error) => {
        showErrorMessage(error.message || t('Something went wrong'));
        setIsUploading(false);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  return (
    <>
      <input
        ref={inputRef}
        accept=".docx"
        className="hidden"
        onChange={handleFileChange}
        type="file"
      />
      <Button
        Icon={<FontAwesomeIcon icon={light('file-arrow-up')} />}
        loading={isUploading}
        onClick={onClick}
        size="xs"
      >
        {t('Upload new EVS template')}
      </Button>
    </>
  );
}

export default MSPSettingsEvsUploadTemplate;
