/* eslint-disable react/no-this-in-sfc,object-shorthand,func-names */
import React from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import * as colorsMap from 'src/design/theme/colors';

function StackedChart(props) {
  const {
    // bgColor is used to add spacing between segments as borderWidth
    bgColor = colorsMap['od-black-300'],
    chartData,
  } = props;
  const { categories, colors, series } = chartData;

  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      backgroundColor: null,
      type: 'column',
    },
    colors,
    credits: {
      enabled: false,
    },
    legend: {
      className: 'bg-od-white-900',
      labelFormatter: function () {
        return (`
          <div class="block od-font-secondary-medium od-font-family flex text-od-white-900 items-center gap-2">
             <div class="h-3.5 w-3.5 rounded" style="background: ${this.color}"></div>
             ${this.name}
          </div>
      `);
      },
      symbolHeight: '0',
      useHTML: true,
    },
    plotOptions: {
      column: {
        borderColor: bgColor,
        borderRadius: 4,
        borderWidth: 4,
        colors,
        dataLabels: {
          enabled: false,
        },
        groupPadding: 0,
        pointPadding: 0.1,
        stacking: 'normal',
      },
      series: {
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
    },
    series,
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: colorsMap['od-black-900'],
      borderRadius: 12,
      borderWidth: 0,
      formatter: function () {
        return (`
          <div class="rounded-xl od-font-secondary-semi-bold od-font-family text-od-white-900 flex items-center gap-2">
             <div class="h-3.5 w-3.5 rounded" style="background: ${this.color}"></div>
             ${this.y} (${this.series.name})
          </div>
      `);
      },
      shadow: false,
      shape: 'square',
      shared: false,
      useHTML: true,
    },
    xAxis: {
      categories,
      labels: {
        formatter: function () {
          return (`
          <div class="od-font-secondary-medium od-font-family text-od-white-500">
             ${this.value}
          </div>
      `);
        },
        useHTML: true,
      },
      lineWidth: 0,
    },
    yAxis: {
      visible: false,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

StackedChart.propTypes = {
  bgColor: PropTypes.string,
  chartData: PropTypes.object.isRequired,
};

export default StackedChart;
